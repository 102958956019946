@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="whiteBlack"] {
  .product-card {
    background: var(--bg-green) !important;
  }
  .product-card_label {
    background: var(--bg-blue-light-2);
  }
}

[data-color-scheme="blackWhite"] {
  .product-card {
    background: var(--bg-blue-light-2) !important;
  }
}

[data-color-scheme="blue"] {
  .product-card {
    background: var(--white) !important;
  }
  .product-card_label {
    background: var(--bg-blue-light-2);
  }
}

.product-card {
  position: relative;
  border-radius: 11px;
  overflow: hidden;
  width: 100%;
  transform: translate3d(0, 0, 0);
  @media only screen and (max-width: $bp-phone) {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }
  &:hover {
    .product-card_image {
      transform: scale(1.02);
    } 
  }
  $colors:
    'green' var(--bg-green-2),
    'green_light' var(--bg-green-light-2),
    'green_dark' var(--bg-green),
    'blue' var(--bg-blue-2),
    'blue_light' var(--bg-blue-light),
    'blue_sky' var(--bg-blue-sky),
    'violet_light' var(--bg-violet-light),
    'brown' var(--bg-brown),
    'brown_light' var(--bg-brown-light),
    'pink' var(--bg-pink),
    'pink_light' var(--bg-pink-light),
    'yellow' var(--bg-yellow),
    'turquoise' var(--bg-turquoise);
  @each $name, $bgBlock in $colors {
    &.bg-#{$name} {
      background: $bgBlock;
    }
  }

  &_head {
    padding: 50px 25px 14px 40px;
    min-height: 148px;
    width: 100%;
    @include desktopStyle {
      padding: get-vw(50px) get-vw(25px) get-vw(14px) get-vw(40px);
      min-height: get-vw(148px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding: 24px;
      min-height: 1px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-right: 0;
      width: 60%;
    }
  }

  &_title {
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2.4rem;
    }
    @media only screen and (max-width: $bp-phone-sm) {
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  &_label {
    background: var(--white);
    border-radius: 66px;
    display: inline-block;
    vertical-align: middle;
    font-family: $font-main-B;
    color: var(--gray-dark);
    font-size: 1.2rem;
    padding: 6px 12px;
    line-height: 1.2;
    margin-top: 16px;
    @include desktopStyle {
      margin-top: get-vw(16px);
      padding: get-vw(6px) get-vw(12px);
      border-radius: get-vw(66px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-top: 12px;
    }
  }

  &_image {
    height: 352px;
    width: 100%;
    will-change: transform;
    transition: transform 0.3s;
    @include desktopStyle {
      height: get-vw(352px);
    }
    @media only screen and (max-width: $bp-tablet) {
      height: 32vw;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 40%;
      height: calc(28.3vw + 50px);
      justify-content: flex-end;
      padding-top: 50px;
      align-items: flex-end;
    }
    picture {
      width: 100%;
      height: 100%;
      @media only screen and (max-width: $bp-phone) {
        justify-content: flex-end;
        align-items: flex-end;
      }
    }
    img {
      display: block;
      width: auto;
      height: auto;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &_over-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    display: block;
    text-decoration: none;
    outline: none;
  }

  &_link {
    overflow: hidden;
    position: absolute;
    right: 20px;
    bottom: 20px;
    gap: 10px;
    grid-gap: 10px;
    height: 52px;
    max-width: 52px;
    z-index: 3;
    @include desktopStyle {
      right: get-vw(20px);
      bottom: get-vw(20px);
      gap: get-vw(10px);
      grid-gap: get-vw(10px);
      height: get-vw(52px);
      max-width: get-vw(52px);
    }
    @media only screen and (max-width: $bp-tablet) {
      max-width: 48px;
      height: 48px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      max-width: none;
    }
    &:global(.bg-white) {
      padding: 13px 16px;
      @include desktopStyle {
        padding: get-vw(13px) get-vw(16px);
      }
      @media only screen and (max-width: $bp-tablet) {
        padding: 12px 14px;
      }
      @media only screen and (max-width: $bp-phone) {
        background: transparent;
        border-color: transparent;
        padding: 32px;
        align-items: flex-start;
      }
      &:hover {
        background: var(--white);
        color: var(--gray-dark);
        border-color: var(--white);
        max-width: 300px;
        @include desktopStyle {
          max-width: get-vw(300px);
        }
        @media only screen and (max-width: $bp-phone) {
          max-width: none;
          background: transparent;
          border-color: transparent;
        }
        .product-card_link-text {
          opacity: 1;
          transition: opacity 0.4s;
        }
      }
    }
  }

  &_link-text {
    white-space: nowrap;
    padding-left: 8px;
    max-width: calc(100% - 26px);
    opacity: 0;
    @include desktopStyle {
      padding-left: get-vw(8px);
      max-width: calc(100% - get-vw(26px));
    }
    @media only screen and (max-width: $bp-phone) {
      display: none;
    }
  }

  &_link-icon {
    min-width: 16px;
    width: 16px;
    height: 16px;
    @include desktopStyle {
      min-width: get-vw(16px);
      width: get-vw(16px);
      height: get-vw(16px);
    }
  }
}
