@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
[data-color-scheme="blue"] {
  .accordion {
    border-top-color: #E8E8E8;
    &:last-child {
      border-bottom-color: #E8E8E8;
    }
    &_btn {
      &::before,
      &:after {
        background: #E8E8E8;
      }
    }
  }
}

.accordion {
  padding: 32px 0;
  border-top: 1px solid var(--gray-second);
  color: var(--gray-dark);
  @include desktopStyle {
    padding-top: get-vw(32px);
    padding-bottom: get-vw(32px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  &:last-child {
    border-bottom: 1px solid var(--gray-second);
  }
  &.b-open {
    .accordion_btn {
      transform: rotate(180deg);
      &::before {
        opacity: 0;
      }
    }
    .accordion_content {
      transition: height 0.3s;
    }
  }

  &_top {
    cursor: pointer;
    position: relative;
    padding-right: 64px;
    font-family: $font-main-SB;
    font-size: 2.4rem;
    line-height: 1.5;
    transition: all 0.35s;
    @include desktopStyle {
      padding-right: get-vw(64px);
    }
    @media only screen and (max-width: $bp-tablet) {
      font-size: 2rem;
    }
    @media only screen and (max-width: $bp-phone) {
      font-size: 1.5rem;
      padding-right: 60px;
      min-height: 35px;
    }
    &:hover {
      color: var(--blue);
      .accordion_btn {
        &::before,
        &:after {
         background: var(--blue); 
        }
      }
    }
  }

  &_title {
    margin: 0 !important;
    color: inherit !important;
    font: inherit;
  }

  &_btn {
    display: block;
    padding: 0;
    border: none;
    position: absolute;
    right: 0;
    top: -2px;
    background: transparent;
    outline: none;
    width: 40px;
    height: 40px;
    transition: all 0.3s;
    @include desktopStyle {
      top: get-vw(-2px);
      width: get-vw(40px);
      height: get-vw(40px);
    }
    @media only screen and (max-width: $bp-tablet) {
      top: -4px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 36px;
      height: 36px;
      top: -1px;
    }
    &::before,
    &:after {
      content: '';
      position: absolute;
      display: block;
      background: var(--gray-light);
      left: 50%;
      top: 50%;
      transition: all 0.35s;
    }
    &::before {
      width: 2px;
      height: 16px;
      margin-left: -1px;
      margin-top: -8px;
      @include desktopStyle {
        height: get-vw(16px);
        margin-top: get-vw(-8px);
      }
    }
    &::after {
      width: 16px;
      height: 2px;
      margin-left: -8px;
      margin-top: -1px;
      @include desktopStyle {
        width: get-vw(16px);
        margin-left: get-vw(-8px);
      }
    }
  }

  &_content {
    height: 0;
    overflow: hidden;
    &:global(.accordion-collapse-enter-done) {
      overflow: visible;
    }
  }

  &_inner {
    padding-top: 24px;
    padding-right: 64px;
    color: var(--gray);
    @include desktopStyle {
      padding-top: get-vw(24px);
      padding-right: get-vw(64px);
    }
    @media only screen and (max-width: $bp-phone) {
      padding-right: 60px;
      padding-top: 16px;
    }
  }
}