@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.service-category {
  padding-bottom: 120px;
  padding-top: 40px;
  background: var(--blue-light);
  @include desktopStyle {
    padding-bottom: get-vw(120px);
    padding-top: get-vw(40px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }
  :global(.breadcrumbs) {
    padding-top: 0;
    padding-bottom: 32px;
    @include desktopStyle {
      padding-bottom: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-bottom: 16px;
    }
  }
  :global(.breadcrumbs .wr) {
    padding: 0;
  }

  &_wrapper {
    gap: 90px 0;
    grid-gap: 90px 0;
    @include desktopStyle {
      gap: get-vw(90px) 0;
      grid-gap: get-vw(90px) 0;
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 45px 0;
      grid-gap: 45px 0;
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 32px 0;
      grid-gap: 32px 0;
    }

    &.b-more-item {
      .service-category_left {
        width: 100%;
      }
      .service-category_right {
        width: 100%;
        :global(.product-card) {
          @media only screen and (min-width: $bp-desktop-md + 1) {
            width: calc(25% - 24px);
          }
          @include desktopStyle {
            width: calc(25% - get-vw(24px));
          }
        }
      }
      .service-category_text,
      .service-category_message {
        max-width: 885px;
      }
    }
  }

  &_left {
    width: 45.4%;
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
    }
  }

  &_right {
    width: 49%;
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 100%;
      gap: 12px;
      grid-gap: 12px;
    }
    :global(.product-card) {
      width: calc(50% - 16px);
      @include desktopStyle {
        width: calc(50% - get-vw(16px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: calc(33.333% - 8px);
      }
      @media only screen and (max-width: $bp-phone) {
        width: 100%;
      }
    }
  }

  &_title {
    margin-bottom: 16px;
    @include desktopStyle {
      margin-bottom: get-vw(16px);
    }
  }

  &_text {
    font-weight: 500;
    color: var(--gray);
    max-width: 622px;
    @include desktopStyle {
      max-width: get-vw(622px);
    }
    :global(a:not(.btn)) {
      color: var(--blue);
      &:hover {
        text-decoration: none;
      }
    }
  }

  &_message {
    background: var(--white);
    border-radius: 12px;
    padding: 24px;
    gap: 16px;
    grid-gap: 16px;
    margin-top: 24px;
    @include desktopStyle {
      border-radius: get-vw(12px);
      padding: get-vw(24px);
      gap: get-vw(16px);
      grid-gap: get-vw(16px);
      margin-top: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      gap: 12px;
      grid-gap: 12px;
      padding: 16px;
      margin-bottom: 16px;
    }
  }
  &_message-icon {
    width: 24px;
    height: 24px;
    display: block;
    @include desktopStyle {
      width: get-vw(24px);
      height: get-vw(24px);
    }
    @media only screen and (max-width: $bp-phone) {
      width: 20px;
      height: 20px;
    }
  }
  &_message-text {
    color: var(--gray);
    font-size: 1.4rem;
    width: calc(100% - 40px);
    @include desktopStyle {
      width: calc(100% - get-vw(40px));
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
      font-size: 1.3rem;
    }
  }
}
