@import "app/assets/style/variables.scss"; @import "app/assets/style/mixin.scss";
.service-subcategories {
  background: var(--blue-light);
  padding-bottom: 120px;
  @include desktopStyle {
    padding-bottom: get-vw(120px);
  }
  @media only screen and (max-width: $bp-tablet) {
    padding-bottom: 80px;
    :global(.breadcrumbs) {
      padding-top: 0;
      padding-bottom: 16px;
    }
  }
  @media only screen and (max-width: $bp-phone) {
    padding-bottom: 40px;
  }
  & + div {
    padding-top: 120px;
    @include desktopStyle {
      padding-top: get-vw(120px);
    }
    @media only screen and (max-width: $bp-tablet) {
      padding-top: 80px;
    }
    @media only screen and (max-width: $bp-phone) {
      padding-top: 40px;
    } 
  }

  &_title {
    width: 100%;
    margin-bottom: 90px;
    @include desktopStyle {
      margin-bottom: get-vw(90px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 32px;
    }
  }

  &_sidebar {
    width: 376px;
    @include desktopStyle {
      width: get-vw(376px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: 270px;
    }
    @media only screen and (max-width: $bp-phone) {
      width: 100%;
    }
  }

  &_content {
    width: calc(100% - 376px);
    padding-left: 32px;
    @include desktopStyle {
      width: calc(100% - get-vw(376px));
      padding-left: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      width: calc(100% - 270px);
      padding-left: 20px;
    }
    @media only screen and (max-width: $bp-phone) {
      display: none;
    }
  }

  &_posts {
    gap: 32px;
    grid-gap: 32px;
    @include desktopStyle {
      gap: get-vw(32px);
      grid-gap: get-vw(32px);
    }
    @media only screen and (max-width: $bp-tablet) {
      gap: 12px;
      grid-gap: 12px;
    }
    :global(.product-card) {
      width: calc(33.333% - 22px);
      height: auto;
      @include desktopStyle {
        width: calc(33.333% - get-vw(22px));
      }
      @media only screen and (max-width: $bp-tablet) {
        width: calc(50% - 6px);
      }
    }
  }

  &_accordion {
    margin-bottom: 24px;
    scroll-margin-top: 80px;
    @include desktopStyle {
      margin-bottom: get-vw(24px);
    }
    @media only screen and (max-width: $bp-tablet) {
      margin-bottom: 16px;
    }
    @media only screen and (max-width: $bp-phone) {
      border-top: 1px solid rgba(var(--gray-second-rgba), 0.4);
      padding: 16px 0;
      margin-bottom: 0;
    }
    &:first-child {
      .service-subcategories_accordion-wrapper {
        height: auto;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }

    &.b-active,
    &-top:hover {
      .service-subcategories_accordion-link {
        color: var(--blue);
        @media only screen and (max-width: $bp-phone) {
          color: var(--gray-dark);
        }

        svg {
          opacity: 1;
          transform: none;
        }
      }
    }

    &.b-active {
      .service-subcategories_accordion-btn {
        transform: rotate(180deg);
      }
    }
  
    &-top {
      position: relative;
      display: inline-flex;
      @media only screen and (max-width: $bp-phone) {
        padding: 3px 56px 3px 0;
        width: 100%;
      }
      &:hover {
        .service-subcategories_accordion-link {
          text-decoration: underline;
          @media only screen and (max-width: $bp-phone) {
            text-decoration: none;
          }
        }
      }
    }
  
    &-link {
      font-family: $font-main-SB;
      color: var(--gray);
      text-decoration: none;
      position: relative;
      cursor: pointer;
      @media only screen and (max-width: $bp-phone) {
        color: var(--gray-dark);
        font-size: 2rem;
        line-height: 1.3;
      }

      svg {
        color: inherit;
        margin-left: 16px;
        width: 20px;
        height: 20px;
        opacity: 0;
        transform: translateX(-5px);
        transition: opacity .3s, transform .3s;
        @include desktopStyle {
          margin-left: get-vw(16px);
          width: get-vw(20px);
          height: get-vw(20px);
        }
        @media only screen and (max-width: $bp-phone) {
          display: none;
        }
      }
    }
  
    &-btn {
      display: none;
      padding: 0;
      background: transparent;
      cursor: pointer;
      border: none;
      position: absolute;
      transition: transform 0.3s;
      top: 50%;
      right: 5px;
      margin-top: -11px;
      width: 22px;
      height: 22px;
      background: url('~~/app/assets/images/icons/arrow-with-circle.svg') center center no-repeat;
      background-size: contain;
      @media only screen and (max-width: $bp-phone) {
        display: block;
      }
    }
  
    &-wrapper {
      display: none;
      height: 0px;
      overflow: hidden;
      transition: height 0.2s;
      @media only screen and (max-width: $bp-phone) {
        display: block;
      }
    }
  
    &-content {
      padding-top: 24px;
      :global(.product-card) {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}